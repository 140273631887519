<script lang="ts" setup>
import { useRoute, withBase } from 'vitepress';
import {
  computed, onMounted, onUnmounted, ref, watch,
} from 'vue';
import HamburgerButton from './HamburgerButton.vue';

type NavLink = { title: string; id: string };

const props = defineProps<{
  navLinks: NavLink[];
}>();

const menuOpen = ref(false);
const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
};

const route = useRoute();
watch(route, () => {
  menuOpen.value = false;
});

const links = computed(() => props.navLinks.map((link) => ({ ...link, url: withBase(link.id) })));

let xlBreakpoint = 0;

const resizeListener = () => {
  if (window.innerWidth >= xlBreakpoint) {
    menuOpen.value = false;
  }
};

onMounted(() => {
  // window does not exist in SSR context
  xlBreakpoint = parseInt(
    window
      .getComputedStyle(document.body)
      .getPropertyValue('--bs-breakpoint-xl'),
    10,
  );
  window.addEventListener('resize', resizeListener);
});
onUnmounted(() => {
  window.removeEventListener('resize', resizeListener);
});
</script>

<template>
  <HamburgerButton
    :is-open="menuOpen"
    aria-controls="menu"
    :aria-expanded="menuOpen"
    @click="toggleMenu"
  />
  <div id="menu" :class="{ open: menuOpen }">
    <a
      v-for="link in links"
      :key="link.id"
      :href="link.url"
      class="nav-link"
      :aria-current="link.url === route.path ? 'page' : false"
      @click="menuOpen = false"
    >
      {{ link.title }}
    </a>
  </div>
</template>

<style lang="scss" scoped>
#menu {
  visibility: hidden;
  position: absolute;
  top: $nav-bar-height;
  left: 0;
  right: 0;
  overflow: hidden;
  transform-origin: top;
  padding-inline: 2rem;
  transform: scaleY(0);
  background: linear-gradient(
    45deg,
    var(--bs-primary),
    var(--bs-primary-light)
  );
  transition:
    transform 0.2s ease-out,
    padding-block 0.2s ease-out,
    visibility 0.2s;

  a {
    text-align: right;

    @include font-size(3rem);

    color: white;
    display: block;
    text-decoration: none;
    font-family: var(--bs-font-headings);

    &:hover,
    &:focus {
      text-decoration: underline;
      opacity: 0.9;
    }
  }

  &.open {
    visibility: visible;
    transform: scaleY(1);
    padding-block: 2rem;
  }

  @include media-breakpoint-up(xl) {
    visibility: visible;
    transform: scaleY(1);
    height: $nav-bar-height;
    position: static;
    padding-inline: 0;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    background: white;
    transition: none;

    a {
      padding: 5px 1rem 0;
      color: black;
      display: flex;
      align-items: center;
      border-bottom: 5px solid transparent;

      @include font-size(1rem);

      &:hover,
      &:focus {
        text-decoration: none;
        border-bottom: 5px solid #555;
      }

      &[aria-current="page"] {
        border-bottom: 5px solid red;
      }
    }
  }
}

@supports (interpolate-size: allow-keywords) {
  #menu {
    transform: none;
    height: 0;
    transition:
      height 0.2s ease-out,
      padding-block 0.2s ease-out,
      visibility 0.2s;

    &.open {
      transform: none;
      height: auto;
    }

    @include media-breakpoint-up(xl) {
      height: $nav-bar-height;
    }
  }
}
</style>
