<script lang="ts" setup>
import {
  ref,
  onMounted,
  onUnmounted,
  watch,
} from 'vue';

const speed = 1;
const position = { x: 0, y: 50 };
const pacman = ref<HTMLDivElement | null>(null);
const richtung = ref('right');
const counter = ref(0);
const active = ref(false);

const inputChars: string[] = [];
const activatePacman = (event: KeyboardEvent) => {
  inputChars.push(event.key);
  if (inputChars.slice(-6).join('') === 'pacman') {
    active.value = true;
    // eslint-disable-next-line no-console
    console.log('Oh nein, Pacman ist da! Steuer ihn mit w a s d oder den Pfeiltasten.');
  }
}

const controlPacman = (e: KeyboardEvent) => {
  if (!active.value) return;

  e.preventDefault()
  switch (e.key) {
    case 'ArrowDown':
    case 's':
      richtung.value = 'down'
      break;
    case 'ArrowUp':
    case 'w':
      richtung.value = 'up'
      break;
    case 'ArrowLeft':
    case 'a':
      richtung.value = 'left'
      break;
    case 'ArrowRight':
    case 'd':
      richtung.value = 'right'
      break;
    default:
      break;
  }
};

onMounted(() => {
  globalThis.addEventListener('keydown', activatePacman);
  globalThis.addEventListener('keydown', controlPacman);
});
onUnmounted(() => {
  globalThis.removeEventListener('keydown', activatePacman);
  globalThis.removeEventListener('keydown', controlPacman);
});

function splitLetters(element: Element) {
  const letters = element.textContent?.split('');
  // eslint-disable-next-line no-param-reassign
  element.textContent = '';
  letters?.forEach((value) => {
    const child = element.appendChild(document.createElement('span'));
    child.classList.add('pacman-letter');
    child.textContent = value;
  });
}

function eat() {
  const essen = document.elementFromPoint(position.x, position.y);

  if (essen === null || essen.classList.contains('hidden') || essen.children.length !== 0) {
    return;
  }

  if (essen.textContent && essen.textContent.length > 1) {
    splitLetters(essen);
    return;
  }
  if (essen.classList.contains('pacman-letter')) {
    essen.classList.add('pacman-hidden');
  } else {
    essen.remove();
  }
  counter.value += 1;
}

const tick = () => {
  if (pacman.value === null) return;
  const maxX = globalThis.document.querySelector('html')?.clientWidth || 0;
  const maxY = globalThis.document.querySelector('html')?.clientHeight || 0;

  if (richtung.value === 'right') position.x += speed;
  if (richtung.value === 'left') position.x -= speed;
  if (richtung.value === 'up') position.y -= speed;
  if (richtung.value === 'down') position.y += speed;
  if (position.x > maxX) position.x = -25;
  if (position.x < -25) position.x = maxX;
  if (position.y > maxY) position.y = -25;
  if (position.y < -25) position.y = maxY;

  eat();

  pacman.value.style.left = `${position.x - 20}px`;
  pacman.value.style.top = `${position.y - 20}px`;
};

watch(active, () => {
  if (active.value) window.setInterval(tick, 12);
});
</script>

<template>
  <div v-if="active">
    <div
      ref="pacman"
      class="pacman"
      :class="richtung"
    />
    <div class="pacmanzaehler">
      {{ counter }}
    </div>
  </div>
</template>

<style>
.pacman-hidden {
  visibility: hidden;
}
</style>

<style scoped>
.pacman {
  position: fixed;
  pointer-events: none;
  z-index: 999;
}

.pacman.right {
  transform: rotate(0);
}

.pacman.left {
  transform: rotate(180deg);
}

.pacman.up {
  transform: rotate(-90deg);
}

.pacman.down {
  transform: rotate(90deg);
}

.pacman::before {
  content: " ";
  display: block;
  width: 40px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 20px solid yellow;
  transform-origin: bottom center;
  animation: pacman-top 0.5s linear infinite;
}

.pacman::after {
  content: " ";
  display: block;
  width: 40px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom: 20px solid yellow;
  transform-origin: top center;
  animation: pacman-bottom 0.5s linear infinite;
}

@keyframes pacman-top {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-45deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes pacman-bottom {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(0);
  }
}

.pacmanzaehler {
  color: blueviolet;
  font-size: 50px;
  position: fixed;
  pointer-events: none;
  top: 20px;
  right: 20px;
  z-index: 999;
  background-color: yellow;
  border-color: darkmagenta;
  border-style: solid;
  border-width: 5px;
}
</style>
