<script lang="ts" setup>
import { useData } from 'vitepress';
import NotFoundImage from 'theme/assets/images/not_found_image.svg';
import TextContainer from './components/containers/TextContainer.vue';

const { site } = useData();
</script>

<template>
  <TextContainer>
    <h1>404 Seite nicht gefunden</h1>
    <div class="not-found-image">
      <NotFoundImage />
    </div>
    <p>
      Wohin du gehst, war noch niemand - sogar wir nicht. Schau dich gerne auf
      unserer
      <a :href="site.base">Startseite</a> um oder
      <a href="/kontakt.html">meld dich</a> bei uns!
    </p>
  </TextContainer>
</template>

<style scoped>
.not-found-image {
  max-width: 600px;
  margin-block: 4rem;
  margin-inline: auto;
}
</style>
